<script lang="ts" setup>
import type { CmsElementProductSlider } from "@shopware-pwa/composables-next";
import { useCmsElementConfig, useUrlResolver } from "#imports";
import { computed, ref } from "vue";
import { mdiArrowLeft, mdiArrowRight } from "@mdi/js";
import type { VSlideGroup } from "vuetify/components";
import TestimonialCard from "~/components/cms/components/TestimonialCard.vue";

const props = defineProps<{
  content: CmsElementProductSlider;
}>();
const { getConfigValue } = useCmsElementConfig(props.content);

const slideGroup: VSlideGroup = ref(null);

const testimonials = computed(() => {
  return getConfigValue("content");
});

const title = computed(() => {
  return getConfigValue("title");
});

const url = computed(() => {
  return getConfigValue("url");
});

const prev = () => {
  slideGroup.value?.scrollTo("prev");
};

const next = () => {
  slideGroup.value?.scrollTo("next");
  // todo: how to check if has Next to disable next btn
};

const { getUrlPrefix } = useUrlResolver();
</script>
<template>
  <v-container class="mb-8">
    <v-row>
      <v-col>
        <div v-if="title" class="text-h2">{{ title }}</div>
      </v-col>

      <v-col cols="auto">
        <v-btn
          v-if="url"
          :href="url"
          color="gray100"
          rounded
          size="large"
          target="_blank"
          variant="flat"
        >
          Weitere Bewertungen
        </v-btn>
      </v-col>
    </v-row>
  </v-container>

  <div :class="{ 'py-5 border border-secondary-300': border }">
    <v-slide-group ref="slideGroup" :show-arrows="false">
      <template #next></template>
      <template #prev></template>
      <template #default>
        <v-slide-group-item
          v-for="(testimonial, index) of testimonials"
          :key="index"
        >
          <TestimonialCard :testimonial="testimonial" />
        </v-slide-group-item>
      </template>
    </v-slide-group>
  </div>

  <v-container>
    <v-row>
      <v-col cols="auto">
        <v-btn
          :disabled="slideGroup?.scrollOffset == 0"
          :icon="mdiArrowLeft"
          @click="prev"
        />
      </v-col>

      <v-col cols="auto">
        <v-btn :icon="mdiArrowRight" @click="next"></v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.v-slide-group {
  flex-wrap: wrap;
}

:deep(.v-slide-group__content) {
  justify-content: center;
}

@media screen and (min-width: 1280px) {
  :deep(.v-slide-group__content) {
    transform: translateX(calc((100vw - 1280px) / 2));
  }
}
</style>
